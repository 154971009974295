.loginPage {
	width: 100%;
	height: 100vh;
	display: flex;
}

.loginPage__latest {
	flex: 1;
	background-color: #f3f3f3;
}

.loginPage__options {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loginPage__loginContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2rem;
}

.loginPage__textContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.loginPage__loginWithGoogle {
	padding: 1rem;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 24px;
	background-color: white;
	border: 2px solid grey;
}

.loginPage__loginWithGoogle:hover {
	background-color: grey;
	color: white;
	border: 2px solid white;
}

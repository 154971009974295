.header {
	height: 7vh;
	width: 100%;
	display: flex;
	align-items: center;
	gap: 2rem;
	padding: 0 2rem;
	border-bottom: 1px solid rgba(128, 128, 128, 0.5);
}

.header__imageContainer {
	display: flex;
	align-items: center;
	height: 70%;
}

.header__menuImage {
	display: block;
	height: 100%;
	cursor: pointer;
}

.header__navbar {
	width: 100%;
}

.header__profileImage {
	display: block;
	height: 100%;
	color: white;
}

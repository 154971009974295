* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-family: "Open Sans", sans-serif;
	z-index: 0;
}

body {
	height: 100vh;
	overflow: hidden;
	transition: all 0.25;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.jsl {
	justify-self: flex-end;
}

.gridLayout {
	min-height: 93vh;
	display: grid;
	grid-template-rows: auto auto auto;
}

.space {
	grid-row: 1/2;
	background-color: aliceblue;
}

.videoPlayer__player {
	grid-row: 2/3;
	background-color: white;
	height: auto;
	width: 100%;
}

.videoPlayer__meta {
	grid-row: 3/4;
	background-color: white;
	padding: 1.35rem;
}

.videoPlayer__playlistComponent {
	grid-row: 4/5;
	background-color: white;
}

@media screen and (min-width: 1024px) {
	.gridLayout {
		grid-template-columns: 65fr 35fr;
		grid-template-rows: 480px auto;
	}

	.videoPlayer__player {
		grid-row: 1/2;
		background-color: white;
		height: 100%;
		width: auto;
		justify-self: center;
		align-self: center;
	}

	.videoPlayer__meta {
		grid-row: 2/3;
		background-color: white;
	}

	.videoPlayer__playlistComponent {
		padding: 0 2rem;
		grid-row: 1/-1;
		grid-column: 2/3;
	}
}

.mediaContainer {
	overflow-y: scroll;
	height: 560px;
	margin: 0 auto;
}

.uploadMedia__module {
	display: flex;
}

.uploadMedia__formSection {
	display: flex;
	flex-direction: column;
}

.uploadMedia__displaySection {
	display: flex;
	flex-direction: column;
}

.mediaCard {
	display: flex;
	width: 90%;
	margin: 1.5rem auto;
	border: 1px solid black;
	border-radius: 10px;
}

.mediaCard__thumbnail {
	flex: 1;
	width: 100%;
	height: 100%;
}

.mediaCard__thumbnail > img {
	width: 100%;
	height: 100%;
	display: block;
	border-radius: 10px 0 0 10px;
}

.mediaCard__meta {
	flex: 5;
	display: flex;
	flex-direction: column;
	padding: 1rem;
}

.mediaCard__action {
	flex: 1;
	align-self: center;
	display: flex;
	gap: 12px;
	align-items: flex-end;
	padding: 1rem;
}

.mediaCard__button {
	margin: 0 4px;
	cursor: pointer;
}

.uploadMedia__sectionTitle {
	padding: 1rem 1rem 0.5rem;
	text-align: center;
}

/* upload form hereafter */

.uploadMedia__form {
	width: 480px;
	margin: 1.5rem;
	padding: 1.5rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.uploadMedia__mediaTitle {
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
}

.uploadMedia__mediaTitle-input {
	outline: none;
	border: none;
	padding: 0.4rem;
	border-bottom: 1px solid grey;
	background-color: #f3f3f3;
}

.uploadMedia__thumbnail {
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
}

.uploadMedia__thumbnail-inputField {
	border: none;
	outline: none;
	display: flex;
	gap: 2rem;
}

.desBox {
	width: 100%;
	margin-top: 0.4rem;
}

.uploadMedia__dropdown {
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
}

.uploadMedia__dropdown-selectField {
	width: 100%;
	border: none;
	outline: none;
	padding: 0.4rem;
	background-color: #f3f3f3;
}

.uploadForm__submitButton {
	border: none;
	outline: none;
	padding: 0.4rem;
	background-color: rgb(100, 98, 98);
	color: white;
	cursor: pointer;
}

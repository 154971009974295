.eudModalMain {
	display: float;
	z-index: 9999;
	position: absolute;
	left: 40%;
	top: 20%;
	padding: 2rem;
	background-color: #fff;
	width: 480px;
	height: auto;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.hideModal {
	display: none;
}

.playlistComponent {
	max-height: 500px;
}

.playlistComponent__playlistName {
	font-size: 24px;
	margin-bottom: 2rem;
	color: #6d6d6d;
}

.playlistComponent__cardHolder {
	display: flex;
	gap: 1.2rem;
	overflow-x: auto;
	overflow-y: hidden;
	max-height: 500px;
	cursor: pointer;
}

.sideReel {
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
}

.playlistComponent__cardHolderTwo {
	display: flex;
	gap: 1.2rem;
	overflow-x: auto;
	overflow-y: hidden;
	max-height: 300px;
	cursor: pointer;
}

@media screen and (max-width: 600px) {
	.playlistComponent__playlistName {
		font-size: 18px;
	}
}

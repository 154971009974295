.videoCard__container {
	background-color: #9c9c9c;
	display: flex;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
}

.videoCard__thumbnailContainer {
	height: 80px;
	width: 80px;
}

.videoCard__thumbnail {
	display: block;
	height: 100%;
	width: 100%;
}

.videoCard__title {
	color: black;
	padding: 1rem;
}

.videoCard__container--two {
	height: auto;
}

.videoCard__thumbnailContainer--two {
	width: 180px;
	height: 180px;
}

.videoCard__title--two {
	background-color: black;
	color: white;
	height: 100%;
	padding: 0.2rem;
	font-weight: 100;
}

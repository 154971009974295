.sidebar__main {
	display: flex;
	height: 93vh;
}

.sidebar__outlet {
	background-color: #ffffff;
	/* overflow-y: scroll; */
	width: 100vw;
}

.sidebar {
	width: 240px;
	z-index: 399;
	height: 93vh;
	background-color: #ffffff;
	border-right: 1px solid rgba(128, 128, 128, 0.5);
	display: flex;
	flex-direction: column;
	/* width: 30%; */
	position: fixed;
}

.sidebarCloseButton {
	margin: 1rem;
	align-self: flex-end;
	outline: none;
	border: none;
	cursor: pointer;
	background-color: white;
}

.sidebarCloseButton__icon {
	height: 25px;
	width: auto;
	padding: 2px 0px;
}

.sideTabComponent {
	margin: 3rem 0;
}

.sideTabComponent__heading {
	padding: 1rem;
	font-weight: normal;
	border-top: 1px solid rgba(128, 128, 128, 0.95);
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
	cursor: pointer;
}

.sideTabComponent__heading:hover {
	background-color: rgba(128, 128, 128, 0.4);
}

.sideTabDrop__container {
	display: flex;
	flex-direction: column;
	/* gap: 1rem; */
	width: 100%;
}

.sideTabDrop__heading {
	width: 100%;
	font-weight: 200;
	padding: 1rem;
	border-bottom: 1px solid rgba(128, 128, 128, 0.4);
	cursor: pointer;
}

.sideTabDrop__heading:hover {
	background-color: rgba(128, 128, 128, 0.4);
}
